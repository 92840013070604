import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'speedux';
import {
  Table,
  Card,
  Spin,
  Popover,
  Tag,
  Switch,
  Tooltip,
  Input,
  Button,
  Select,
  InputNumber,
  Space,
  Menu,
  Dropdown,
} from 'antd';
import {
  CheckCircleOutlined,
  InfoCircleOutlined,
  SelectOutlined,
  SendOutlined,
  ReloadOutlined,
  SettingOutlined,
  EditOutlined,
  CommentOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import useMedia from 'use-media';
import Cookies from 'universal-cookie';

import StatusCircle from 'components/StatusCircle';
import PageHeader from 'components/PageHeader';
import PageLayout from 'components/PageLayout';
import Module from './module';
import {
  AUTH_EXPIRATION_PERIOD,
  COOKIES_CAMPAIGN_DISABLED_ALERTS,
  LAPTOP_SCREEN_HEIGHT,
  MOB_SCREEN_WIDTH,
  campaignsSourceMap,
} from '../../utils/constants';
import { columns } from './monitoring-table-columns';
import {
  TagsContainerStyle,
  RulesContainerStyle,
  ButtonsContainerStyle,
  RuleContainerStyle,
  FilterContainerStyle,
  CommentContainerStyle,
  SelectHealthStatusStyle,
  SelectCampaignStateStyle,
} from './styles';
import { DivFlexSpaceBetween, Circle } from 'styles/styledComponents';

import theme from 'styles/themes';

import { ALERT_NAME_MAPPER } from 'utils/helpers';
import { pathAllCampaigns } from 'routes/routes';
import EditableField from 'components/EditableField';

const { TextArea } = Input;
const { Option } = Select;
const defaultPageSize = 20;
const cookies = new Cookies();
const CAMPAIGN_STATES_FILTER = [
  { name: 'ALL', value: '' },
  { name: 'RUNNING', value: 'RUNNING' },
  { name: 'EXPIRED', value: 'EXPIRED' },
];
const Monitoring = ({
  actions: {
    getMonitoringData,
    addComment,
    setCommentObj,
    editComment,
    deleteComment,
    setCommentUpdateText,
    refreshCampaign,
    getMonitoringRules,
    editMonitoringRule,
    resetEditRule,
    getCampaignsComments,
    resetGetComments,
  },
  state: {
    monitoringData,
    alerts,
    campaignsCount,
    getMonitoringDataLoading,
    addCommentLoading,
    selectedCampaign,
    commentObj,
    editCommentLoading,
    commentEditMode,
    commentUpdatedInput,
    commentUpdated,
    getMonitoringRulesLoading,
    monitoringRules,
    editMonitoringRuleLoading,
    editRuleDone,
    campaignIds,
    campaignsComments,
    deleteCommentLoading,
    shouldGetComments,
    getCommentsLoading,
    selectedComment,
  },
  dispatch,
}) => {
  const [campaignDisabledAlerts, setCampaignDisabledAlerts] = useState(
    cookies.get(COOKIES_CAMPAIGN_DISABLED_ALERTS) || {},
  );
  const [selectedAlerts, setSelectedAlerts] = useState([]);
  const [selectedCampaignState, setSelectedCampaignState] = useState('RUNNING');
  const [selectedCampaignSource, setSelectedCampaignSource] = useState('ALL');
  const [ruleToBeEdit, setRuleToBeEdit] = useState(null);
  const clicksRef = useRef(null);
  const visitsRef = useRef(null);
  const discrepancyPercentageRef = useRef(null);
  const timeOnSiteRef = useRef(null);
  const slowPercentageRef = useRef(null);
  const overPercentageRef = useRef(null);
  const searchInputRef = useRef(null);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [pagination, setPagination] = useState({
    size: pageSize,
    from: 0,
  });
  const [pageNumber, setPageNumber] = useState(1);
  const paginationConfig = {
    size: 'large',
    total: campaignsCount,
    defaultPageSize,
    showSizeChanger: true,
    current: pageNumber,
    onChange: pageNumber => {
      setPagination({
        size: pageSize,
        from: pageSize * (pageNumber - 1),
      });
      setPageNumber(pageNumber);
    },
    onShowSizeChange: (current, size) => {
      setPageSize(size);
      setPageNumber(1);
      setPagination({
        size,
        from: 0,
      });
    },
  };

  const [sortConfig, setSortConfig] = useState({
    sort: '',
  });

  useEffect(() => {
    getMonitoringData(
      { ...pagination, ...sortConfig },
      selectedCampaignState,
      selectedAlerts,
      selectedCampaignSource,
    );
  }, [pagination, selectedAlerts, selectedCampaignState, selectedCampaignSource, sortConfig]); //eslint-disable-line

  useEffect(() => {
    if (editRuleDone) {
      setRuleToBeEdit(null);
    }

    return () => resetEditRule();
  }, [editRuleDone]); //eslint-disable-line

  useEffect(() => {
    if (campaignIds?.length) {
      getCampaignsComments();
    }
  }, [campaignIds]); //eslint-disable-line

  useEffect(() => {
    if (shouldGetComments) {
      getCampaignsComments();
    }

    return () => resetGetComments();
  }, [shouldGetComments]); //eslint-disable-line

  const isLaptop = useMedia({ minHeight: LAPTOP_SCREEN_HEIGHT });
  const isMob = useMedia({ maxWidth: MOB_SCREEN_WIDTH });

  const toggleAlert = (value, name, id) => {
    const newCookies = { ...campaignDisabledAlerts };

    if (!value) {
      newCookies[id] = campaignDisabledAlerts[id]
        ? [...campaignDisabledAlerts[id], name]
        : [name];
    } else {
      newCookies[id].splice(newCookies[id].indexOf(name), 1);
    }

    setCampaignDisabledAlerts(newCookies);

    cookies.set(COOKIES_CAMPAIGN_DISABLED_ALERTS, JSON.stringify(newCookies), {
      maxAge: AUTH_EXPIRATION_PERIOD,
    });
  };

  const getRules = isVisible => {
    if (isVisible) {
      getMonitoringRules();
    } else {
      setRuleToBeEdit(null);
    }
  };

  const editRule = (ruleId, newRule) => {
    editMonitoringRule(ruleId, newRule);
  };

  const getColumnSearchProps = dataIndex => {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInputRef}
            placeholder={`Search ${dataIndex.replace('_', ' ')}`}
            value={selectedKeys[0]}
            onChange={e =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => {
              paginationConfig.onChange(1);
              setPageNumber(1);
              setTimeout(() => getMonitoringDataAction());
            }}
            style={{ width: 200, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => {
                paginationConfig.onChange(1);
                setPageNumber(1);
                setTimeout(() => getMonitoringDataAction());
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 96 }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
                setTimeout(() => getMonitoringDataAction(true));
              }}
              size="small"
              style={{ width: 96 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),

      filterIcon: filtered => <SearchOutlined style={{ color: '#fff' }} />,

      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInputRef.current.focus());
        }
      },
    };
  };

  const getMonitoringDataAction = all => {
    getMonitoringData(
      {
        ...pagination,
        ...(searchInputRef?.current?.state?.value ? { from: 0 } : {}),
        ...sortConfig,
        ...(!all &&
        selectedCampaignState === '' &&
        searchInputRef?.current?.state?.value
          ? { q: `campaign_id:${searchInputRef?.current?.state?.value}` }
          : {}),
      },
      selectedCampaignState,
      selectedAlerts,
      selectedCampaignSource,
      !all ? searchInputRef?.current?.state?.value : '*',
    );
  };

  const rulesPopOverContent = (
    <Spin spinning={getMonitoringRulesLoading}>
      <RulesContainerStyle>
        {monitoringRules?.map(rule => {
          const editMode = ruleToBeEdit === rule.name;

          switch (rule.name) {
            case 'monitoring_no_clicks_critical':
              return (
                <RuleContainerStyle>
                  <Circle />
                  Campaign isn’t getting any clicks for at least 24 hrs!
                </RuleContainerStyle>
              );
            case 'monitoring_clicks_no_visits_critical':
              return (
                <RuleContainerStyle editMode={editMode}>
                  <Circle />
                  {editMode ? (
                    <>
                      Campaign has more than{' '}
                      <InputNumber
                        style={{ width: 55 }}
                        min={0}
                        defaultValue={rule.valuesToBeModified.clicks}
                        ref={clicksRef}
                      />{' '}
                      clicks but{' '}
                      <InputNumber
                        style={{ width: 55 }}
                        min={0}
                        defaultValue={rule.valuesToBeModified.visits}
                        ref={visitsRef}
                      />{' '}
                      visits!
                      <ButtonsContainerStyle>
                        <Button
                          onClick={() => setRuleToBeEdit(null)}
                          style={{ marginRight: 10 }}
                        >
                          CANCEL
                        </Button>
                        <Button
                          type="primary"
                          loading={editMonitoringRuleLoading}
                          onClick={() => {
                            const newClicks = clicksRef.current.state.value;
                            const newVisits = visitsRef.current.state.value;

                            const newRule = rule.getRuleAfterModify(
                              newClicks,
                              newVisits,
                            );

                            editRule(rule.id, newRule);
                          }}
                        >
                          save
                        </Button>
                      </ButtonsContainerStyle>
                    </>
                  ) : (
                    <>
                      Campaign has more than {rule.valuesToBeModified.clicks}{' '}
                      clicks but {rule.valuesToBeModified.visits} visits!
                      <EditOutlined
                        style={{ fontSize: 18, position: 'absolute', right: 0 }}
                        onClick={() => setRuleToBeEdit(rule.name)}
                      />
                    </>
                  )}
                </RuleContainerStyle>
              );
            case 'monitoring_budget_exceeded_critical':
              return (
                <RuleContainerStyle editMode={editMode}>
                  <Circle />
                  Total campaign budget exceeded!
                </RuleContainerStyle>
              );
            case 'monitoring_clicks_visits_discrepancy_medium':
              return (
                <RuleContainerStyle editMode={editMode}>
                  <Circle />
                  {editMode ? (
                    <>
                      There is a discrepancy between clicks and visits of more
                      than{' '}
                      <InputNumber
                        style={{ width: 55 }}
                        min={0}
                        defaultValue={
                          rule.valuesToBeModified.discrepancyPercentage * 100
                        }
                        ref={discrepancyPercentageRef}
                      />{' '}
                      %
                      <ButtonsContainerStyle>
                        <Button
                          onClick={() => setRuleToBeEdit(null)}
                          style={{ marginRight: 10 }}
                        >
                          CANCEL
                        </Button>
                        <Button
                          type="primary"
                          loading={editMonitoringRuleLoading}
                          onClick={() => {
                            const newDiscrepancyPercentage =
                              discrepancyPercentageRef.current.state.value;

                            const newRule = rule.getRuleAfterModify(
                              newDiscrepancyPercentage,
                            );

                            editRule(rule.id, newRule);
                          }}
                        >
                          save
                        </Button>
                      </ButtonsContainerStyle>
                    </>
                  ) : (
                    <>
                      There is a discrepancy between clicks and visits of more
                      than {rule.valuesToBeModified.discrepancyPercentage * 100}{' '}
                      %!
                      <EditOutlined
                        style={{ fontSize: 18, position: 'absolute', right: 0 }}
                        onClick={() => setRuleToBeEdit(rule.name)}
                      />
                    </>
                  )}
                </RuleContainerStyle>
              );
            case 'monitoring_bad_quality_medium':
              return (
                <RuleContainerStyle editMode={editMode}>
                  <Circle />
                  {editMode ? (
                    <>
                      Quality is bad: time on site is less than{' '}
                      <InputNumber
                        style={{ width: 55 }}
                        min={0}
                        defaultValue={rule.valuesToBeModified.timeOnSite}
                        ref={timeOnSiteRef}
                      />{' '}
                      seconds!
                      <ButtonsContainerStyle>
                        <Button
                          onClick={() => setRuleToBeEdit(null)}
                          style={{ marginRight: 10 }}
                        >
                          CANCEL
                        </Button>
                        <Button
                          type="primary"
                          loading={editMonitoringRuleLoading}
                          onClick={() => {
                            const newTimeOnSite =
                              timeOnSiteRef.current.state.value;

                            const newRule = rule.getRuleAfterModify(
                              newTimeOnSite,
                            );

                            editRule(rule.id, newRule);
                          }}
                        >
                          save
                        </Button>
                      </ButtonsContainerStyle>
                    </>
                  ) : (
                    <>
                      Quality is bad: time on site is less than{' '}
                      {rule.valuesToBeModified.timeOnSite} seconds!
                      <EditOutlined
                        style={{ fontSize: 18, position: 'absolute', right: 0 }}
                        onClick={() => setRuleToBeEdit(rule.name)}
                      />
                    </>
                  )}
                </RuleContainerStyle>
              );
            case 'monitoring_slow_delivery_medium':
              return (
                <RuleContainerStyle editMode={editMode}>
                  <Circle />
                  {editMode ? (
                    <>
                      Slow Delivery: expected spent budget `{'>'}` actual spent
                      budget by{' '}
                      <InputNumber
                        style={{ width: 55 }}
                        min={0}
                        defaultValue={
                          100 - rule.valuesToBeModified.slowPercentage * 100
                        }
                        ref={slowPercentageRef}
                      />{' '}
                      %
                      <ButtonsContainerStyle>
                        <Button
                          onClick={() => setRuleToBeEdit(null)}
                          style={{ marginRight: 10 }}
                        >
                          CANCEL
                        </Button>
                        <Button
                          type="primary"
                          loading={editMonitoringRuleLoading}
                          onClick={() => {
                            const newSlowPercentage =
                              slowPercentageRef.current.state.value;

                            const newRule = rule.getRuleAfterModify(
                              newSlowPercentage,
                            );

                            editRule(rule.id, newRule);
                          }}
                        >
                          save
                        </Button>
                      </ButtonsContainerStyle>
                    </>
                  ) : (
                    <>
                      Slow Delivery: expected spent budget `${'>'}` actual spent
                      budget by{' '}
                      {100 - rule.valuesToBeModified.slowPercentage * 100} %
                      <EditOutlined
                        style={{ fontSize: 18, position: 'absolute', right: 0 }}
                        onClick={() => setRuleToBeEdit(rule.name)}
                      />
                    </>
                  )}
                </RuleContainerStyle>
              );
            case 'monitoring_over_delivery_medium':
              return (
                <RuleContainerStyle editMode={editMode}>
                  <Circle />
                  {editMode ? (
                    <>
                      Over delivery: actual spent budget `${'>'}` expected spent
                      budget by{' '}
                      <InputNumber
                        style={{ width: 55 }}
                        min={0}
                        defaultValue={
                          100 - rule.valuesToBeModified.overPercentage * 100
                        }
                        ref={overPercentageRef}
                      />{' '}
                      %
                      <ButtonsContainerStyle>
                        <Button
                          onClick={() => setRuleToBeEdit(null)}
                          style={{ marginRight: 10 }}
                        >
                          CANCEL
                        </Button>
                        <Button
                          type="primary"
                          loading={editMonitoringRuleLoading}
                          onClick={() => {
                            const newOverPercentage =
                              overPercentageRef.current.state.value;

                            const newRule = rule.getRuleAfterModify(
                              newOverPercentage,
                            );

                            editRule(rule.id, newRule);
                          }}
                        >
                          save
                        </Button>
                      </ButtonsContainerStyle>
                    </>
                  ) : (
                    <>
                      Over delivery: actual spent budget `${'>'}` expected spent
                      budget by{' '}
                      {100 - rule.valuesToBeModified.overPercentage * 100} %
                      <EditOutlined
                        style={{ fontSize: 18, position: 'absolute', right: 0 }}
                        onClick={() => setRuleToBeEdit(rule.name)}
                      />
                    </>
                  )}
                </RuleContainerStyle>
              );
            default:
              return (
                <RuleContainerStyle editMode={editMode}>
                  {rule.name}
                </RuleContainerStyle>
              );
          }
        })}
      </RulesContainerStyle>
    </Spin>
  );

  const handleTableChange = (pagination, filter, sorter) => {
    if (sorter?.order === 'ascend') {
      setSortConfig({
        sort: `${sorter?.field}:asc`,
      });
      paginationConfig.onChange(1);
      setPageNumber(1);
    } else if (sorter?.order === 'descend') {
      setSortConfig({
        sort: `${sorter?.field}:desc`,
      });
      paginationConfig.onChange(1);
      setPageNumber(1);
    } else {
      setSortConfig({
        sort: '',
      });
      paginationConfig.onChange(1);
    }
  };

  const renderSourceList = () => (
    <Menu
      onClick={({ key: selectedSource }) => {
        setSelectedCampaignSource(selectedSource);
        setPageNumber(1);
        setPagination({
          size: pageSize,
          from: 0,
        });
      }}
    >
      {Object.keys(campaignsSourceMap).map(source => {
        const { title, color } = campaignsSourceMap[source];

        return (
          <Menu.Item key={source} data-test={`source-${source}`}>
            <StatusCircle title={title} color={color} />
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const lastUpdatedAt = monitoringData?.[0]?.created_at || '';

  return (
    <PageLayout
      pageHeader={
        <>
          <PageHeader
            title={
              <div>
                Monitoring{' '}
                {lastUpdatedAt ? (
                  <span
                    style={{ fontSize: 10, color: theme?.colors?.grayLight }}
                  >
                    last updated at: {lastUpdatedAt}
                  </span>
                ) : (
                  ''
                )}
              </div>
            }
          />
          <Button
            type="primary"
            icon={<ReloadOutlined />}
            style={{ marginRight: 20 }}
            onClick={() => {
              dispatch('topNav.refreshMonitoring');
            }}
          >
            Refresh Data
          </Button>

          <Popover
            placement="leftTop"
            trigger="click"
            content={rulesPopOverContent}
            onVisibleChange={getRules}
          >
            <Button type="primary" icon={<SettingOutlined />}>
              health indicator rules
            </Button>
          </Popover>
        </>
      }
      pageBody={
        <Card
          bodyStyle={{
            maxHeight: '100%',
          }}
        >
          <FilterContainerStyle isMob={isMob}>
            <SelectHealthStatusStyle isMob={isMob}>
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Filter campaigns by health status"
                data-test="healthStatus-select"
                onChange={value => {
                  setSelectedAlerts(value);
                  setPageNumber(1);
                  setPagination({
                    size: pageSize,
                    from: 0,
                  });
                }}
              >
                {alerts?.map(({ name }) => (
                  <Option key={name}>{name}</Option>
                ))}
              </Select>
            </SelectHealthStatusStyle>
            <SelectCampaignStateStyle isMob={isMob}>
              <Select
                data-test="campaignState-select"
                value={selectedCampaignState}
                style={{ width: '100%' }}
                onChange={value => {
                  setSelectedCampaignState(value);
                  setPageNumber(1);
                  setPagination({
                    size: pageSize,
                    from: 0,
                  });
                }}
              >
                {CAMPAIGN_STATES_FILTER.map(({ name, value }) => (
                  <Option value={value} key={name}>
                    {name}
                  </Option>
                ))}
              </Select>
            </SelectCampaignStateStyle>
            <Dropdown.Button
              overlay={renderSourceList}
              data-test="change-campaign-source"
              trigger={['click', 'hover']}
            >
              <StatusCircle
                title={campaignsSourceMap[selectedCampaignSource]?.title}
                color={campaignsSourceMap[selectedCampaignSource]?.color}
              />
            </Dropdown.Button>
          </FilterContainerStyle>

          <Spin spinning={getMonitoringDataLoading} size="large">
            <Table
              pagination={paginationConfig}
              columns={[
                {
                  title: 'ID',
                  dataIndex: 'campaign_id',
                  key: 'campaign_id',
                  width: 80,
                  fixed: !isMob ? 'left' : false,
                  ...getColumnSearchProps('campaign_id'),
                },
                {
                  title: 'Name',
                  dataIndex: 'campaign_name',
                  key: 'campaign_name',
                  width: 250,
                  ellipsis: true,
                  fixed: !isMob ? 'left' : false,
                },
                ...columns,
                {
                  dataIndex: '',
                  key: 'alerts',
                  fixed: 'right',
                  align: 'center',
                  width: 160,
                  render: data => {
                    const hasCriticalAlerts = data?.criticalAlerts?.length;
                    const hasMediumAlerts = data?.mediumAlerts?.length;
                    const hasNaAlerts = data?.naAlerts?.length;
                    const campaignComments = campaignsComments?.filter(obj => {
                      return +obj.campaign_id === data.campaign_id;
                    });
                    const hasComments = campaignComments?.length;

                    return (
                      <>
                        {hasCriticalAlerts || hasMediumAlerts || hasNaAlerts ? (
                          <Popover
                            content={
                              <TagsContainerStyle>
                                {hasCriticalAlerts
                                  ? data?.criticalAlerts.map(alert => (
                                      <DivFlexSpaceBetween key={alert}>
                                        <Tag
                                          color="error"
                                          style={{ flexGrow: 1 }}
                                        >
                                          {ALERT_NAME_MAPPER?.[alert]}
                                        </Tag>
                                        <Switch
                                          name={alert}
                                          checked={
                                            !campaignDisabledAlerts?.[
                                              data?.campaign_id
                                            ]?.includes(alert)
                                          }
                                          onChange={(value, event) =>
                                            toggleAlert(
                                              value,
                                              event?.target?.name,
                                              data?.campaign_id,
                                            )
                                          }
                                        />
                                      </DivFlexSpaceBetween>
                                    ))
                                  : ''}

                                {hasMediumAlerts
                                  ? data?.mediumAlerts.map(alert => (
                                      <DivFlexSpaceBetween key={alert}>
                                        <Tag
                                          color="warning"
                                          style={{ flexGrow: 1 }}
                                        >
                                          {ALERT_NAME_MAPPER?.[alert]}
                                        </Tag>
                                        <Switch
                                          name={alert}
                                          checked={
                                            !campaignDisabledAlerts?.[
                                              data?.campaign_id
                                            ]?.includes(alert)
                                          }
                                          onChange={(value, event) =>
                                            toggleAlert(
                                              value,
                                              event?.target?.name,
                                              data?.campaign_id,
                                            )
                                          }
                                        />
                                      </DivFlexSpaceBetween>
                                    ))
                                  : ''}

                                {hasNaAlerts
                                  ? data?.naAlerts.map(alert => (
                                      <DivFlexSpaceBetween key={alert}>
                                        <Tag
                                          color="purple"
                                          style={{ flexGrow: 1 }}
                                        >
                                          {ALERT_NAME_MAPPER?.[alert]}
                                        </Tag>
                                        <Switch
                                          name={alert}
                                          checked={
                                            !campaignDisabledAlerts?.[
                                              data?.campaign_id
                                            ]?.includes(alert)
                                          }
                                          onChange={(value, event) =>
                                            toggleAlert(
                                              value,
                                              event?.target?.name,
                                              data?.campaign_id,
                                            )
                                          }
                                        />
                                      </DivFlexSpaceBetween>
                                    ))
                                  : ''}
                              </TagsContainerStyle>
                            }
                            placement="left"
                            trigger="click"
                          >
                            <Tooltip title="Alerts">
                              <InfoCircleOutlined
                                style={{
                                  fontSize: 25,
                                  color: hasCriticalAlerts
                                    ? theme.colors.red
                                    : hasMediumAlerts
                                    ? theme.colors.orange
                                    : theme.colors.mainLight,
                                }}
                              />
                            </Tooltip>
                          </Popover>
                        ) : (
                          <CheckCircleOutlined style={{ fontSize: 25 }} />
                        )}
                        <Popover
                          content={
                            <div
                              style={{
                                width: 300,
                              }}
                            >
                              <Spin spinning={getCommentsLoading}>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                  }}
                                >
                                  {campaignComments?.map(comment => {
                                    const { text, id, campaign_id } = comment;
                                    return (
                                      <CommentContainerStyle key={id}>
                                        <Circle color={theme.colors.main} />
                                        <EditableField
                                          editMode={commentEditMode}
                                          value={text}
                                          onSave={() => {
                                            editComment(
                                              id,
                                              campaign_id,
                                              commentUpdatedInput,
                                            );
                                          }}
                                          onDelete={() => {
                                            deleteComment(id);
                                          }}
                                          loadingDelete={
                                            deleteCommentLoading &&
                                            selectedComment === id
                                          }
                                          onCancel={() => {
                                            setCommentUpdateText('');
                                          }}
                                          loadingEdit={editCommentLoading}
                                        >
                                          <Input
                                            placeholder="Edit this comment"
                                            defaultValue={text}
                                            onChange={e =>
                                              setCommentUpdateText(
                                                e.target.value,
                                              )
                                            }
                                          />
                                        </EditableField>
                                      </CommentContainerStyle>
                                    );
                                  })}
                                </div>
                              </Spin>
                              <div
                                style={{
                                  display: 'flex',
                                }}
                              >
                                <TextArea
                                  rows={1}
                                  placeholder={'Add Comment'}
                                  value={commentObj[data.campaign_id] || ''}
                                  onChange={e => {
                                    setCommentObj({
                                      ...commentObj,
                                      [data.campaign_id]: e.target.value,
                                    });
                                  }}
                                />
                                <Button
                                  type="primary"
                                  icon={<SendOutlined />}
                                  style={{ marginLeft: 10 }}
                                  loading={
                                    addCommentLoading &&
                                    selectedCampaign === data.campaign_id
                                  }
                                  onClick={() => {
                                    const { campaign_id } = data;

                                    addComment(
                                      campaign_id,
                                      commentObj[campaign_id],
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          }
                          placement="left"
                          trigger="click"
                        >
                          <Tooltip title="Comments">
                            <CommentOutlined
                              style={{
                                fontSize: 25,
                                marginLeft: 10,
                                color: hasComments
                                  ? theme.colors.green
                                  : 'inherit',
                              }}
                            />
                          </Tooltip>
                        </Popover>
                        <Link
                          to={`${pathAllCampaigns}?state=ALL&search=${data?.campaign_id}`}
                        >
                          <Tooltip title="Go to campaign">
                            <SelectOutlined
                              style={{ fontSize: 25, marginLeft: 10 }}
                            />
                          </Tooltip>
                        </Link>

                        <Tooltip title="Refresh">
                          <ReloadOutlined
                            onClick={() => {
                              refreshCampaign(data.campaign_id);
                            }}
                            style={{
                              fontSize: 25,
                              marginLeft: 10,
                              cursor: 'pointer',
                            }}
                            className="reloadIcon"
                          />
                        </Tooltip>
                      </>
                    );
                  },
                },
              ]}
              rowKey="campaign_id"
              dataSource={monitoringData}
              size="middle"
              scroll={{ x: 300, y: isLaptop ? 630 : 410 }}
              rowClassName={record => {
                const hasCriticalAlerts = record?.criticalAlerts?.some(
                  e => !campaignDisabledAlerts[record.campaign_id]?.includes(e),
                );
                const hasMediumAlerts = record?.mediumAlerts?.some(
                  e => !campaignDisabledAlerts[record.campaign_id]?.includes(e),
                );
                const hasNaAlerts = record?.naAlerts?.some(
                  e => !campaignDisabledAlerts[record.campaign_id]?.includes(e),
                );

                if (hasCriticalAlerts) {
                  return 'red-row';
                } else if (hasMediumAlerts) {
                  return 'orange-row';
                } else if (hasNaAlerts) {
                  return 'purple-row';
                }
                return '';
              }}
              onChange={handleTableChange}
            />
          </Spin>
        </Card>
      }
    />
  );
};

export default connect(Monitoring, Module);
