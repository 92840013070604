import React, { useState, useEffect, useRef } from 'react';
import { Drawer, BackTop } from 'antd';
import PageLayout from 'components/PageLayout';
import PageHeader from 'components/PageHeader';
import AddNewCampaignForm from 'components/AddCustomCampaignForm';
import useMedia from 'use-media';
import { connect } from 'speedux';
import Module from './module';
import CampaignDetails from '../CampaignDetails';
import CampaignsList from 'components/CampaignsList';
import { MOB_SCREEN_WIDTH, MOB_WIDTH_FOR_DRAWER } from 'utils/constants';
import ViewAsUser from 'components/ViewAsUser';
import GaIntegration from 'components/Integrations/ga';
import Report from 'pages/Report';
import History from 'pages/History';
import CostReport from 'pages/CostReport';

const scrollToRef = ref => {
  window.scrollTo({ top: ref?.current?.offsetTop, behavior: 'smooth' });
};

const Campaigns = ({
  state: { campaignAdded, ...reduxState },
  actions: { resetCampaignAdded, getAllCampaigns, getAllPartners },
}) => {
  const isMob = useMedia({ maxWidth: MOB_SCREEN_WIDTH });
  const focusRef = useRef(null);
  const [selectedTabKey, selectSectionTabKey] = useState('1');
  const [subSectionTabSelected, selectSubSectionFromTab] = useState('1');
  const [viewAsUserModal, setViewAsUserModal] = useState(false);
  const [isReportView, setIsReportView] = useState(false);
  const [isHistoryView, setIsHistoryView] = useState(false);
  const [isCostReportView, setIsCostReportView] = useState(false);
  const [isGaIntegratedView, triggerGaIntegrationView] = useState(false);
  const [drawerIsVisible, setDrawerIsVisible] = useState(false);
  const [currentCampaign, selectCampaign] = useState({
    id: '',
    name: '',
    url: '',
    deliveryUtmSource: '',
    balance: 0,
  });

  const onSelectSpecificSection = (sectionKey, subSectionKey) => {
    selectSectionTabKey(sectionKey);
    selectSubSectionFromTab(subSectionKey);
  };

  useEffect(() => {
    if (campaignAdded) {
      toggleAddCampaignDrawer();
    }

    return () => resetCampaignAdded();
  }, [campaignAdded]); //eslint-disable-line

  const toggleAddCampaignDrawer = () => {
    setDrawerIsVisible(!drawerIsVisible);
  };

  const renderCampaignBody = () => {
    if (isGaIntegratedView) {
      return <GaIntegration campaign={currentCampaign} />;
    }

    if (viewAsUserModal) {
      return (
        <ViewAsUser
          viewAsUserModal={viewAsUserModal}
          setViewAsUserModal={setViewAsUserModal}
          campaignId={currentCampaign.id}
          campaignName={currentCampaign.name}
        />
      );
    }

    if (isReportView) {
      return (
        <Report
          campaignId={currentCampaign.id}
          campaignName={currentCampaign.name}
        />
      );
    }

    if (isHistoryView) {
      return (
        <History
          campaignId={currentCampaign.id}
          campaignName={currentCampaign.name}
        />
      );
    }

    if (isCostReportView) {
      return (
        <CostReport
          campaignId={currentCampaign.id}
          campaignName={currentCampaign.name}
          campaignBudget={currentCampaign.balance}
          createdAt={currentCampaign.createdAt}
        />
      );
    }

    return (
      <CampaignDetails
        campaignId={currentCampaign.id}
        selectedTabKey={selectedTabKey}
        selectSectionTabKey={selectSectionTabKey}
        subSectionTabSelected={subSectionTabSelected}
        selectSubSectionFromTab={selectSubSectionFromTab}
        getAllCampaigns={getAllCampaigns}
      />
    );
  };

  const toggleSelectedView = ({
    id,
    name,
    url,
    deliveryUtmSource,
    view,
    balance,
    createdAt,
  }) => {
    selectCampaign({
      ...currentCampaign,
      id,
      name,
      url,
      deliveryUtmSource,
      balance,
      createdAt,
    });

    const views = {
      viewAsUser: setViewAsUserModal,
      gaReport: triggerGaIntegrationView,
      report: setIsReportView,
      history: setIsHistoryView,
      costReport: setIsCostReportView,
    };

    for (const v in views) {
      views[v](v === view);
    }

    scrollToRef(focusRef);
  };

  return (
    <PageLayout
      pageHeader={
        <PageHeader
          title="campaigns"
          btnText={'add custom campaign'}
          btnOnChange={() => toggleAddCampaignDrawer()}
          btnTestId={'addCustomBtn'}
        >
          <Drawer
            title="ADD NEW CAMPAIGN"
            placement="right"
            {...(isMob
              ? { width: MOB_WIDTH_FOR_DRAWER }
              : { width: MOB_SCREEN_WIDTH })}
            onClose={toggleAddCampaignDrawer}
            visible={drawerIsVisible}
            destroyOnClose
          >
            <AddNewCampaignForm />
          </Drawer>
        </PageHeader>
      }
      pageBody={
        <>
          <CampaignsList
            onSelectSpecificSection={onSelectSpecificSection}
            getAllPartners={getAllPartners}
            getAllCampaigns={getAllCampaigns}
            resetCampaignAdded={resetCampaignAdded}
            state={reduxState}
            onClickCampaign={id => {
              toggleSelectedView({ id, view: 'campaign' });
            }}
            onViewAsUserClick={(id, name) => {
              toggleSelectedView({ id, view: 'viewAsUser', name });
            }}
            onViewReport={(id, name) => {
              toggleSelectedView({ id, view: 'report', name });
            }}
            onViewHistory={(id, name) => {
              toggleSelectedView({ id, view: 'history', name });
            }}
            onViewCostReport={(id, name, balance, createdAt) => {
              toggleSelectedView({
                id,
                view: 'costReport',
                name,
                balance,
                createdAt,
              });
            }}
            setViewAsUserModal={setViewAsUserModal}
            triggerGaIntegrationView={({
              id,
              name,
              url,
              deliveryUtmSource,
            }) => {
              toggleSelectedView({
                id,
                view: 'gaReport',
                name,
                url,
                deliveryUtmSource,
              });
            }}
          />

          {renderCampaignBody()}
          <span ref={focusRef} />
          <BackTop />
        </>
      }
    />
  );
};

export default connect(Campaigns, Module);
