import { Alert, Typography } from 'antd';
import { connect } from 'speedux';
import cognitevLogo from 'images/cognitev.png';
import metadspLogo from 'images/metadsp.png';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { pathForgetPassword, pathRoot } from 'routes/routes';
import styled from 'styled-components';

import LoginForm from './form';
import Module from './module';

const { Title } = Typography;

const LoginContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.main};
`;

const LoginHeadContainer = styled.div`
  display: flex;
  width: 30rem;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;

  h3 {
    color: ${props => props.theme.colors.white};
  }

  img {
    width: 100%;
  }
`;

const LoginFormContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  width: 36rem;

  .ant-alert {
    margin-bottom: 1rem;
  }

  form {
    width: 100%;
  }
`;

const Login = props => {
  const history = useHistory();

  useEffect(() => {
    if (props.state.isLoggedIn) {
      history.push(pathRoot);

      return () => props.actions.resetLoggedInState();
    }
  }, [history, props.actions, props.state.isLoggedIn]);

  const handleSubmitLoginForm = e => {
    e.preventDefault();

    const {
      email: { value: emailValue },
      password: { value: passwordValue },
      remember: { checked: rememberMe },
    } = e.target;

    props.actions.submitLoginForm(
      {
        email: emailValue,
        password: passwordValue,
      },
      rememberMe,
    );
  };

  const app = process.env.REACT_APP_APPLICATION;
  const canResetPassword = process.env.REACT_APP_CAN_RESET_PASSWORD === 'true';

  return (
    <LoginContainer>
      <LoginHeadContainer>
        <img src={app === 'admin' ? cognitevLogo : metadspLogo} alt="Logo" />
        <Title level={3}>
          {app === 'admin' ? 'Admin Dashboard QA test' : 'Partner Portal'}
        </Title>
      </LoginHeadContainer>
      <LoginFormContainer>
        <Title level={4}> Login </Title>
        {props.state.error && (
          <Alert message={props.state.errorMsg} type="error" />
        )}
        <LoginForm
          handleSubmitLoginForm={handleSubmitLoginForm}
          canResetPassword={canResetPassword}
          pathForgetPassword={pathForgetPassword}
          loading={props.state.loading}
        />
      </LoginFormContainer>
    </LoginContainer>
  );
};

export default connect(Login, Module);
